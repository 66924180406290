import classes from "./Mission.module.css";

const Mission = () => {
    return(
        <div className={classes.container}>
            <div className={classes.card}>
                <h2>Our Mission</h2>
                <p>At Punekar Yatra Company, our mission is to inspire and facilitate seamless travel experiences across India. We are committed to providing exceptional travel management and car rental services, ensuring utmost comfort, safety, and satisfaction for our customers.</p>
            </div>
            <div className={classes.card}>
                <h2>Our Vision</h2>
                <p>Our vision at Punekar Yatra Company is to redefine the standards of excellence in Pan-India travel management and car rental services. We envision a future where every traveler can explore the rich cultural heritage, natural wonders, and vibrant communities of India with ease and confidence. </p>
            </div>
        </div>
    );
};
export default Mission;