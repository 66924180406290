import "./index.css";
import { BrowserRouter,Routes,Route } from "react-router-dom";

import Home from "./Components/PAGES/Home";
import About from "./Components/PAGES/About";
import Services from "./Components/PAGES/Services";
import Contact from "./Components/PAGES/Contact";
import Gallary from "./Components/GALLARY/Gallary";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />} />
        <Route path="/gallary" element={<Gallary/>}/>
        <Route path="/contact" element={<Contact />} />
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
