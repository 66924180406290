import classes from "./About3.module.css";
import image from "./IMAGE/About3.jpg";

const About2 = () =>{
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Who We Are</h1>
                <div className={classes.underline}></div>
                <p>
                At Punekar Yatra Company, we don't just offer tours; we craft unforgettable journeys through the heart of Pune's rich culture, history, and heritage. With us, every step you take is a story waiting to be told, every sight a glimpse into Pune's vibrant past and present.<br/><br/>
                Punekar Yatra Company isn't just another tour operator; we're a passionate team of locals deeply connected to our city's soul. Our roots in Pune run deep, and it's this profound love for our hometown that drives us to share its wonders with the world.<br/><br/>
                We're on a mission to redefine travel experiences, one tour at a time. Through personalized itineraries, expert guides, and immersive activities, we aim to unravel the hidden treasures , leaving you with memories to cherish for a lifetime.
                </p>
            </div>
            <div className={classes.right}>
                <img src={image} alt="Punekar Yatra" />
            </div>
        </div>
    );
};
export default About2;