import Navbar from "../NAVBAR/Navbar";
import Headline3 from "../SERVICES/Headline3";
import Hotelbooking from "../SERVICES/Hotelbooking";
import Domestic from "../SERVICES/Domestic";
import Customise from "../SERVICES/Customise"
import CarRental from "../SERVICES/CarRental"
import Footer from "../FOOTER/Footer"
import { useEffect } from "react";
import Banner from "../ABOUT/Banner"
import Watsapp from "../Watsapp";


const Services = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[]);
    return(
        <>
        <Navbar />
        <Hotelbooking/>
        <Domestic/>
        <Banner/>
        <CarRental/>
        <Customise/>
        <Watsapp/>
        <Footer />
        </>       
    );
};
export default Services;