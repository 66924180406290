import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classes from "./OurPackageSlider.module.css";
import image1 from "../IMAGES/1.jpeg";
import image2 from "../IMAGES/2.jpeg";
import image3 from "../IMAGES/3.jpeg";
import image4 from "../IMAGES/4.jpeg";
import image5 from "../IMAGES/5.jpeg";
import image6 from "../IMAGES/6.jpeg";
import image7 from "../IMAGES/7.jpeg";
import image8 from "../IMAGES/8.jpeg";
import image9 from "../IMAGES/9.jpeg";
import image10 from "../IMAGES/10.jpeg";

const OurPackageSlider = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className={classes.container}>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={4000}>
                <div className={classes.card}>
                    <img src={image1} alt="Punekar Yatra" />
                </div>
                <div className={classes.card}>
                    <img src={image2} alt="Punekar Yatra" />
                </div>
                <div className={classes.card}>
                    <img src={image3} alt="Punekar Yatra" />
                </div>
                <div className={classes.card}>
                    <img src={image4} alt="Punekar Yatra" />
                </div>
                <div className={classes.card}>
                    <img src={image5} alt="Punekar Yatra" />
                </div>
                <div className={classes.card}>
                    <img src={image6} alt="Punekar Yatra" />
                </div>
                <div className={classes.card}>
                    <img src={image7} alt="Punekar Yatra" />
                </div>
                <div className={classes.card}>
                    <img src={image8} alt="Punekar Yatra" />
                </div>
                <div className={classes.card}>
                    <img src={image9} alt="Punekar Yatra" />
                </div>
                <div className={classes.card}>
                    <img src={image10} alt="Punekar Yatra" />
                </div>
            </Carousel>
        </div>
    );
};
export default OurPackageSlider;
