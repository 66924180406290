import Hero from "../HOME/Hero";
import OurPackages from "../HOME/OurPackages";
import Navbar from "../NAVBAR/Navbar";
import OurPackageSlider from "../HOME/OurPackageSlider";
import OurServices from "../HOME/OurServices";
import OurService2 from "../HOME/OurService2";
import WhyUs from "../HOME/WhyUs";
import Testimonial from "../HOME/Testimonial";
import Footer from "../FOOTER/Footer";
import { useEffect } from "react";
import Watsapp from "../Watsapp";
import About2 from "../ABOUT/About2";
import HomeBan from "../HOME/HomeBan";

const Home = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[]);
    return(
        <>
        <Navbar />
        <Hero /> 
        <OurPackages />
        <OurPackageSlider />
        <OurServices />
        <About2/>
        <HomeBan/>
        <OurService2/>
        <WhyUs/>
        <Testimonial />
        <Watsapp/>
        <Footer />
        </>
    );
};
export default Home;