import classes from "./ContactUS.module.css";
import image from "./IMAGE/contact.jpg";

const ContactUs = () => {
    return (
        <>
            <div className={classes.head}>
                <h1>Contact Us</h1>
            </div>

            <div className={classes.container}>
                <div className={classes.left}>
                    <form>
                        <input type="text" placeholder="Enter Your Full Name" />
                        <input type="number" placeholder="Enter Your Mobile Number" />
                        <textarea type="text" placeholder="Enter Message" />
                        <button>SUBMIT</button>
                    </form>
                </div>
                <div className={classes.right}>
                    <img src={image} alt="Punekar_Yatra" />
                </div>
            </div>
        </>
    );
};
export default ContactUs;