import React from 'react'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import DP from "./IMAGES/logo.png";

const Watsapp = () => {
    return(
        <FloatingWhatsApp 
        phoneNumber=''
        accountName="Punekar Yatra Company"
        avatar={DP}
        />
    );
};
export default Watsapp;