import classes from "./Gallary.module.css";
import Navbar from "../NAVBAR/Navbar";
import Footer from "../FOOTER/Footer";
import Watsapp from "../Watsapp";
import image1 from "./IMAGE/1.jpeg";
import image2 from "./IMAGE/2.jpeg";
import image3 from "./IMAGE/3.mp4";
import image4 from "./IMAGE/4.mp4";
import image5 from "./IMAGE/5.jpeg";
import image6 from "./IMAGE/6.jpeg";
import image7 from "./IMAGE/7.jpeg";
import image8 from "./IMAGE/8.jpeg";
import image9 from "./IMAGE/9.mp4";
import image10 from "./IMAGE/10.jpeg";
import image11 from "./IMAGE/11.jpeg";
import image12 from "./IMAGE/12.mp4";
import image13 from "./IMAGE/13.mp4";
import image14 from "./IMAGE/14.jpeg";
import image15 from "./IMAGE/15.mp4";
import image16 from "./IMAGE/16.mp4";
import image17 from "./IMAGE/17.jpeg";
import image18 from "./IMAGE/18.jpeg";
import image19 from "./IMAGE/19.jpeg";
import image20 from "./IMAGE/20.jpeg";
import image21 from "./IMAGE/21.jpeg";
import image22 from "./IMAGE/22.jpeg";
import image23 from "./IMAGE/23.mp4";
import image24 from "./IMAGE/24.mp4";


const Gallary = () => {
    return (
        <div>
            <Navbar />
            <div className={classes.head}>
                <h1>My Gallary</h1>
            </div>
            <div className={classes.imgContainer}>
                <div className={classes.row}>
                    <img src={image1} alt='Punekar Yatra' />
                    <img src={image2} alt='Punekar Yatra' />
                    <video autoPlay={true} muted controls loop src={image3} alt='Punekar Yatra' />
                    <video autoPlay={true} muted controls loop src={image4} alt='Punekar Yatra' />

                </div>
                <div className={classes.row}>
                    <img src={image5} alt='Punekar Yatra' />
                    <img src={image6} alt='Punekar Yatra' />
                    <img src={image7} alt='Punekar Yatra' />
                    <img src={image8} alt='Punekar Yatra' />

                </div>
                <div className={classes.row}>
                    <video autoPlay={true} muted controls loop src={image9} alt='Punekar Yatra' />
                    <img src={image10} alt='Punekar Yatra' />
                    <img src={image11} alt='Punekar Yatra' />
                    <video autoPlay={true} muted controls loop src={image12} alt='Punekar Yatra' />
                </div>
                <div className={classes.row}>
                    <video autoPlay={true} muted controls loop src={image13} alt='Punekar Yatra' />
                    <img src={image14} alt='Punekar Yatra' />
                    <video autoPlay={true} muted controls loop src={image15} alt='Punekar Yatra' />
                    <video autoPlay={true} muted controls loop src={image16} alt='Punekar Yatra' />

                </div>
                <div className={classes.row}>
                    <img src={image17} alt='Punekar Yatra' />
                    <img src={image18} alt='Punekar Yatra' />
                    <img src={image19} alt='Punekar Yatra' />
                    <img src={image20} alt='Punekar Yatra' />

                </div>
                <div className={classes.row}>
                    <img src={image21} alt='Punekar Yatra' />
                    <img src={image22} alt='Punekar Yatra' />
                    <video autoPlay={true} muted controls loop src={image23} alt='Punekar Yatra' />
                    <video autoPlay={true} muted controls loop src={image24} alt='Punekar Yatra' />

                </div>
            </div>
            <Watsapp />
            <Footer />
        </div>
    )
}
export default Gallary;