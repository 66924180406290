import classes from "./OurService2.module.css";

import ticket from "./ServiceImages/ticket.png";
import holiday from "./ServiceImages/holiday.png";
import celebrity from "./ServiceImages/famous.png";
import ring from "./ServiceImages/rings.png";
import meeting from "./ServiceImages/meeting.png";
import calender from "./ServiceImages/calendar.png";
import car from "./ServiceImages/car.png";
import travelinsurence from "./ServiceImages/travel-insurance.png";
import booking from "./ServiceImages/using-phone.png";

const OurService2 = () => {
    return (
        <section className={classes.main_container}>
            <div className={classes.head}>
                <h1>Services We Provide</h1>
                <div className={classes.underline}></div>
            </div>

            <div className={classes.container}>
                <div className={classes.card}>
                    <img src={ticket} alt="online ticket booking" />
                    <h2>Ticketing</h2>
                    <p>Domestic and International tickets at best prices</p>
                </div>
                <div className={classes.card}>
                    <img src={holiday} alt="online ticket booking" />
                    <h2>Customized Holidays</h2>
                    <p>You name it. We design it. At the most affordable rates</p>
                </div>
                <div className={classes.card}>
                    <img src={celebrity} alt="online ticket booking" />
                    <h2>Celebrity Tour Management</h2>
                    <p>Hassle Free solutions from start to end</p>
                </div>
                <div className={classes.card}>
                    <img src={ring} alt="online ticket booking" />
                    <h2>Destination Wedding</h2>
                    <p>Premium help with all your on-site personalized wedding needs</p>
                </div>
                <div className={classes.card}>
                    <img src={meeting} alt="online ticket booking" />
                    <h2>Corporate Conferences</h2>
                    <p>Creating memorable events for your business strategies and let you be creative</p>
                </div>
                <div className={classes.card}>
                    <img src={calender} alt="online ticket booking" />
                    <h2>Event Management</h2>
                    <p>Enjoy your momemts. Leave your Event management to us.</p>
                </div>
                <div className={classes.card}>
                    <img src={car} alt="online ticket booking" />
                    <h2>Car Decoration</h2>
                    <p>Decorate car for Wedding Ceremony. </p>
                </div>
                <div className={classes.card}>
                    <img src={travelinsurence} alt="online ticket booking" />
                    <h2>Travel Insurance</h2>
                    <p>Covers risks during travel protect you and your family</p>
                </div>
                <div className={classes.card}>
                    <img src={booking} alt="online ticket booking" />
                    <h2>Cruise Bookings</h2>
                    <p>Plan the Trip of a Lifetime Explore Unique Itineraries</p>
                </div>
            </div>
        </section>
    );
};
export default OurService2;