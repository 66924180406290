import classes from "./About2.module.css";
import image from "./IMAGE/2151049529.jpg";

const About2 = () =>{
    return(
        <div className={classes.container}>
            <div className={classes.left}>
                <h1>Punekar Yatra Company</h1>
                <div className={classes.underline}></div>
                <p>
                At Punekar Yatra Company, we understand that each journey is more than just a destination; it's an opportunity to create lasting memories and forge unforgettable experiences. With our extensive knowledge and expertise in the travel industry, we strive to curate meticulously planned itineraries that showcase the best of India, ensuring that every moment of your trip is filled with excitement, discovery, and adventure.<br/><br/>
                At Punekar Yatra Company, we believe in fostering meaningful connections and creating moments that will be cherished for a lifetime. Whether you're a solo traveler, a couple, a family, or a group of friends, we welcome you to embark on a journey of discovery with us and experience the richness and diversity of India like never before.
                </p>
            </div>
            <div className={classes.right}>
                <img src={image} alt="Punekar Yatra" />
            </div>
        </div>
    );
};
export default About2;