import About1 from "../ABOUT/About1";
import Mission from "../ABOUT/Mission";
import Navbar from "../NAVBAR/Navbar";
import Footer from "../FOOTER/Footer";
import { useEffect } from "react";
import Testimonial from "../HOME/Testimonial";
import Banner from "../ABOUT/Banner";
import About2 from "../ABOUT/About2";
import Watsapp from "../Watsapp";
import About3 from "../ABOUT/About3"


const About = () => {
    useEffect(()=>{
        window.scrollTo(0, 0);
    },[]);
    return(
        <>
        <Navbar />
        <About1/>
        <About2/>             
        <Banner/>
        <Mission/>
        <About3 />
        <Testimonial/>
        <Watsapp />
        <Footer />
        </>
    );
};
export default About;