import classes from "./About1.module.css";

const About1 = () => {
    return (
        <>
            <div className={classes.head}>
                <h1>About Us</h1>
            </div>
            <div className={classes.container}>
                <div className={classes.text}>
                    <h1>About Punekar Yatra Company</h1>
                    <p>Welcome to Punekar Yatra Company, your trusted partner in exploring the diverse landscapes, cultures, and experiences that India has to offer. Established in 2012, Punekar Yatra Company has been at the forefront of providing unparalleled travel experiences, tailored to meet the unique preferences and interests of our valued clients.<br /><br />
                        Punekar Yatra Company is a premier Pan-India travel management and car rental service, dedicated to providing seamless and unforgettable travel experiences. With a focus on excellence and customer satisfaction, Punekar Yatra offers a wide array of services tailored to meet the diverse needs of travelers across the country.<br /><br />
                        From organizing meticulously planned tours to offering reliable and comfortable car rental solutions, Punekar Yatra ensures that every aspect of your journey is taken care of with utmost professionalism and attention to detail. Whether you're exploring the bustling streets of Mumbai, marveling at the majestic forts of Rajasthan, or soaking in the serene beauty of Kerala, Punekar Yatra is your trusted partner in discovering the wonders of India.<br /><br />
                        With a fleet of well-maintained vehicles and a team of experienced drivers, Punekar Yatra guarantees a safe and enjoyable travel experience, allowing you to sit back, relax, and immerse yourself in the journey. Whether you're traveling solo, with family, or in a group, Punekar Yatra's personalized approach ensures that your travel needs are met with precision and care.<br /><br />
                        With a commitment to excellence and a passion for travel, Punekar Yatra Company stands as a beacon of reliability and quality in the realm of Pan-India travel management and car rental services. Let Punekar Yatra be your companion on your next adventure, and experience the joy of hassle-free travel like never before.
                    </p>
                </div>
            </div>
        </>
    );
};
export default About1;