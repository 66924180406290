import classes from "./WhyUs.module.css";

import smartphone from "./BestImages/smartphone.png";
import onestop from "./BestImages/problem-solving.png";
import airplane from "./BestImages/airplane.png";
import faires from "./BestImages/best-price.png";
const WhyUs = () => {
    return (
        <div className={classes.main_container}>
            <div className={classes.head}>
                <h1>Why Punekar Yatra ?</h1>
                <div className={classes.underline}></div>
            </div>
            <div className={classes.content}>
                <div className={classes.card}>
                    <img  src={smartphone} alt="Punekar_Yatra" />
                    <h2>Easy online Booking Available</h2>
                </div>
                <div className={classes.card}>
                    <img  src={onestop} alt="Punekar_Yatra" />
                    <h2>One Stop for All Travel Services</h2>
                </div>
                <div className={classes.card}>
                    <img  src={airplane} alt="Punekar_Yatra" />
                    <h2>Instant Deals on Flights & Holidays</h2>
                </div>
                <div className={classes.card}>
                    <img  src={faires} alt="Punekar_Yatra" />
                    <h2>Assured Best Fares on all Services</h2>
                </div>

            </div>
        </div>
    );
};
export default WhyUs;