import ContactUs from "../CONTACT/ContactUs";
import Navbar from "../NAVBAR/Navbar";
import Footer from "../FOOTER/Footer"
import { useEffect } from "react";
import Watsapp from "../Watsapp";

const Contact = () => {
    useEffect(()=>{
        window.scrollTo(0,0)
    },[]);
    return(
        <>
        <Navbar />
        <ContactUs/>
        <Watsapp/>
        <Footer/>
        </>
    );
};
export default Contact;