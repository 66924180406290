import classes from "./OurServices.module.css";
import video from "./BestImages/INDIA.mp4"



const OurServices = () => {
    return (
        <div className={classes.main_Container}>
            <div className={classes.container}>
                <div className={classes.left}>
                    <div className={classes.left_Top}>
                        <div className={classes.overlay_text1}>
                            <h1>Explore Himachal's Hidden Gems</h1>
                            <p>Discover the Magic of Himachal Pradesh with Himalayan Adventures</p>
                        </div>
                    </div>
                    <div className={classes.left_Bottom}>
                        <div className={classes.L_B_Left}>
                        <div className={classes.overlay_text2}>
                            <h1>Discover the Soul of South India</h1>
                        </div>
                        </div>
                        <div className={classes.L_B_Right}>
                        <div className={classes.overlay_text2}>
                            <h1>Your Beach Adventure Awaits!</h1>
                        </div>
                        </div>
                    </div>
                </div>
                <div className={classes.right}>
                    <div className={classes.R1}>
                        <h1>BEST TOURS AND TRAVELS</h1>
                        <p>Embark on the Journey of a Lifetime with Wanderlust Travels: Where Adventure Knows No Bounds !</p>
                    </div>
                    <div className={classes.R2}>
                        <video src={video} muted autoPlay loop />
                    </div>
                </div>
            </div>
        </div>
    );
};
export default OurServices;
