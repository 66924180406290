import classes from "./Hero.module.css";
import hero from "../IMAGES/Hero.mp4"
import logo from "../IMAGES/logo.png"

const Hero = () => {
    return(
        <div className={classes.hero_container}>
            <video src={hero} autoPlay muted loop />
            {/*<img src={logo} alt="" />*/}
        </div>
    );
};
export default Hero;