import classes from "./Footer.module.css";
import LOGO from "../IMAGES/logo.png";
import location from "../IMAGES/placeholder.png";
import gmail from "../IMAGES/gmail.png";
import phone from "../IMAGES/receiver.png";


const Footer = () =>{
    return(
        <div className={classes.container}>
            <div className={classes.top}>
                <div className={classes.card}>
                    <img src={LOGO} alt="Punekar_Yatra" />
                </div>
                <div className={classes.card}>
                    <div className={classes.row}>
                        <img src={location} alt=""/>
                        <p>Panderi Nath Nagar, Shop No. 4, NDA RD., Shinde Pool, Shivne, Pune-4110023</p>
                    </div>
                    <div className={classes.row}>
                        <img src={gmail} alt=""/>
                        <p>punekaryatraco@gmail.com </p>
                    </div>
                    <div className={classes.row}>
                        <img src={phone} alt=""/>
                        <p>+91 8055358080 | +91 9529111752</p>
                    </div>
                </div>
                <div className={classes.card}>
                    <h2>About Punekar Yatra</h2>
                    <div className={classes.underline}></div>
                    <p>Punekar Yatra Company, your gateway to unforgettable journeys through Pune's rich cultural tapestry and scenic landscapes. Let us guide you to the heart of Maharashtra's vibrant spirit, one adventure at a time.</p>
                </div>
            </div>
            <div className={classes.bottom}>
                <p>Copyright © 2024 Punekar Yatra | Powered by EXADS IT SOLUTIONS PVT. LTD</p>
            </div>
        </div>
    );
};
export default Footer;