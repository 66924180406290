import classes from "./HomeBan.module.css";
import { Link } from "react-router-dom";

const HomeBan = () => {
    return(
        <div className={classes.container}>
            <h1>Unlock the beauty of India with Punekar Yatra Company</h1>  
            <h2>Your passport to unforgettable adventures!</h2>
            <p>Call us now at <Link className={classes.phn} to='tel: +91 8055358080'>+91 8055358080</Link> to start your journey.</p>
            
        </div>
    );
};
export default HomeBan;