import { Link } from "react-router-dom";
import classes from "./Banner.module.css";

const Banner = () =>{
    return(
        <div className={classes.container}>
            <h1>Embark on unforgettable journeys with Punekar Yatra Company </h1>
            <p>where every destination becomes an adventure!</p>
            <button><Link className={classes.lnk} to="/contact">Book Your Trip</Link></button>
        </div>
    );
};
export default Banner;