import classes from "./Testimonial.module.css";
import TestimonialSlider from "./TestimonialSlider";

const Testimonial = () => {
    return(
        <div className={classes.container}>
        <div className={classes.head}>
            <h1>What Customer's Say About Us !</h1>
            <div className={classes.underline}></div>
        </div>
        <div className={classes.sliderContainer}>
            <TestimonialSlider />
        </div>
        </div>
    );
};
export default Testimonial;