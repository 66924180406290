import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classes from "./TSlider.module.css";
import image1 from "./BestImages/user.png"


const OurPackageSlider = () => {
    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    return (
        <div className={classes.container}>
            <Carousel
                responsive={responsive}
                infinite={true}
                autoPlay={true}
                autoPlaySpeed={4000}>
                <div className={classes.card}>
                    <img src={image1} alt="Punekar Yatra" />
                    <h2>Mr. Dipak</h2>
                    <p>Exceptional service and attention to detail Punekar Yatra made our dream vacation a reality</p>
                </div>
                <div className={classes.card}>
                    <img src={image1} alt="Punekar Yatra" />
                    <h2>Mrs. Shaha</h2>
                    <p>Impeccably planned itinerary and knowledgeable guides Punekar Yatra Company exceeded all our travel expectations.</p>
                </div>
                <div className={classes.card}>
                    <img src={image1} alt="Punekar Yatra" />
                    <h2>Mr. Manoj D.</h2>
                    <p>From start to finish, Punekar Yatra Company ensured our trip was seamless, leaving us with unforgettable experiences.</p>
                </div>
                <div className={classes.card}>
                    <img src={image1} alt="Punekar Yatra" />
                    <h2>Sweta Sharma</h2>
                    <p>Highly recommend Punekar Yatra for their professionalism, reliability, and dedication to creating magical journeys.</p>
                </div>
                <div className={classes.card}>
                    <img src={image1} alt="Punekar Yatra" />
                    <h2>Mr. Biju K.</h2>
                    <p>Thanks to Punekar Yatra Company, our vacation was nothing short of perfection - they truly go above and beyond for their clients.</p>
                </div>
                
            </Carousel>
        </div>
    );
};
export default OurPackageSlider;
